import React from "react"

export default function SuccessMessage() {
    return (
        <div className="flex flex-col space-y-6 bg-primary-alt py-16 px-4 sm:px-6 lg:px-8 lg:py-24 justify-center items-center" style={{height:"calc(var(--vh, 1vh) * 75)"}}>
            <div className="mx-auto text-center">
                <p className="text-secondary uppercase font-body tracking-widest mb-4">Thank you!</p>
                <h1 className="text-4xl tracking-tight leading-10 font-extrabold text-white font-display sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
                    We will be in contact shortly
            </h1>
                <span className="inline-flex rounded-md shadow mt-8">
                    <a href="/" className="inline-flex items-center px-4 py-2 text-base leading-6 font-medium rounded-md text-black bg-yellow-300 ">
                        Home
                </a>
                </span>
            </div>
        </div>
    )
}
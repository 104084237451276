import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import SEO from "../components/SEO"
import NavBar from "../components/NavBar"
import SuccessMessage from "../components/SuccessMessage"

export default function Contact(props) {
    return (
        <Layout>
            <SEO
                title="Thank you!"
            />
            <NavBar />
            <SuccessMessage />
            <Footer />

        </Layout>
    )
}
